import './issuesCard.scss';

import warning_icon from '../../images/icons/ornage_circle.webp';
import arrow_icon from '../../images/icons/point_icon.png';
import check_icon from '../../images/icons/check-mark-10120.svg'
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SkeletonText from '../Skeleton/SkeletonText';
import { useTranslation } from 'react-i18next';

const IssuesCard = ({data}) => {

    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const {details} = data || {};
    const loading = useSelector(state => state.loading);
    const allActive = useSelector(state => state.allActive);
    const lang = useSelector(state => state.language);
    const link = data ? data.description.match(/\[(.*?)\]\((.*?)\)/)[2] : null;
    const {items} = details || {};

    let variable = details && details.overallSavingsMs && 'overallSavingsMs' in details ? 
    details.overallSavingsMs : 
    items ? Object.keys(items).length : null;
    
    useEffect(() =>{
        if (loading) {
            setActive(false);
        }
    }, [loading]);


    let icon = items && items.length < 1 ? check_icon : warning_icon;

    let cardModel = null;
    if (!data) {
        cardModel = null;
    }
    else if (data.id === 'layout-shift-elements') {
        cardModel = items ? items.map((item, id) => (
        <tr key={id} className='table__row'>
            <th className='table__head-item'>{item.node.snippet}</th>
            <th className='table__head-performance'>{item.score.toFixed(5)}</th>
        </tr>
        )): null;
    }
    else if (data.id === 'uses-long-cache-ttl') {
        cardModel = items ? items.map((item, id) => (
            <tr key={id} className='table__row'>
                <th className='table__head-item'><a href={item.url} target='_blanc'>{item.url}</a></th>
                <th className='table__head-performance'>{(item.totalBytes / 1024).toFixed(2)} KiB</th>
            </tr>
        )): null;
    }
    else if (data.id === 'render-blocking-resources') {
        cardModel = items ? items.map((item, id) => (
            <tr key={id} className='table__row'>
                <th className='table__head-item'><a href={item.url} target='_blanc'>{item.url}</a></th>
                <th className='table__head-performance'>{item.wastedMs} ms</th>
            </tr>
        )): null;
    }
    else if (data.id === 'unused-css-rules') {
        cardModel = cardModel = items ? items.map((item, id) => (
            <tr key={id} className='table__row'>
                <th className='table__head-item'><a href={item.url} target='_blanc'>{item.url}</a></th>
                <th className='table__head-performance'>{(item.totalBytes / 1024).toFixed(2)} KiB</th>
            </tr>
        )): null;
    }
    else if (data.id === 'redirects') {
        cardModel = cardModel = items ? items.map((item, id) => (
            <tr key={id} className='table__row'>
                <th className='table__head-item'><a href={item.url} target='_blanc'>{item.url}</a></th>
                <th className='table__head-performance'>{item.wastedMs} ms</th>
            </tr>
        )): null;
    } 
    else if (data.id === 'critical-request-chains') {

        if(!data || !data.details || !data.details.chains) {
            cardModel = null;
            return;
        }

        const keyss = data && data.details && data.details.chains ? Object.keys(data.details.chains) : null;
        const dataArr = data?.detais?.chains && data?.detais?.chains[keyss[0]]?.children ? Object.values(data.details.chains[keyss[0]].children) : null;
        variable = dataArr?.length || 0;

        cardModel = dataArr ? dataArr.map((item, id) => (
            <tr key={id} className='table__row'>
                <th className='table__head-item'><a href={item.request.url} target='_blanc'>{item.request.url}</a></th>
                <th className='table__head-performance'>{(item.request.endTime - item.request.startTime).toFixed(5)} ms</th>
            </tr>
        )) : []
    }
    else if (data.id === 'unused-javascript') {
        cardModel = cardModel = items ? items.map((item, id) => (
            <tr key={id} className='table__row'>
                <th className='table__head-item'><a href={item.url} target='_blanc'>{item.url}</a></th>
                <th className='table__head-performance'>{(item.totalBytes / 1024).toFixed(2)} KiB</th>
            </tr>
        )): null;
    }
    else cardModel = null;



    const toggleMoreInfo = () => {
        setActive(!active);
    }

    const arrowIconClasses = active ? "arrow__icon arrow__icon-active" : "arrow__icon";

    return data ? ( 
        <div className="issues__card--wrapper">
            <div className="issuer__card--container">
                <div className="issues__card--main" onClick={loading || !data ?  null : toggleMoreInfo}>
                    
                    {loading ? <SkeletonText/> :
                    <>
                    <img className="issues__card--icon" src={data ? icon : null} alt="performance icon" />
                    <p className='issues__card--name'>{data ? t(`${data.id}-title`) : null}</p>
                    <p className="issues__card--info">{data ? t(`${data.id}-info`, {variable}) : null}</p>
                    </>
                    }
                    <div className="arrow__more--container">
                        <img className={arrowIconClasses} src={arrow_icon} alt="open acordion icon" />
                    </div>
                </div>

                {active || allActive ? 
                <div className="issues__card--content">
                    <div className="audit__desc">
                        <p className='audit__text'>{t(`${data.id}-desc`)}</p>
                        <Button variant='primary' size='sm' target='_blanc' href={`${link}?hl=${lang}`}>{t('improve-btn')}</Button>
                    </div>

                    <div className='audit__content'>
                        <p className='audit__text'>{t('res-table')}:</p>
                        <div className='audit__content--data'>
                            {(items && items.length > 0) || data.id === 'critical-request-chains' ?
                            <table className='audit__table'>
                                <thead>
                                    <tr>
                                        <th className='table__head-name'>{t('table-item')}</th>
                                        <th className='table__head-influence'>{t('table-influence')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cardModel}
                                </tbody>
                            </table> :
                            <p className='audit__text'>{t('all-good-msg')}</p>}
                        </div>
                    
                    
                    </div>
                </div>
                : null}
            </div>
        </div>
    ) : null
}
 
export default IssuesCard;
import React, { useEffect, useState } from 'react'
import loadingSpinner from "../../images/gif/loading.gif"
import { useTranslation } from 'react-i18next'

const LoadingSpinner = () => {
    const { t } = useTranslation();
    const [ activeText, setActiveText ] = useState(t('loading-data'));
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (activeText === t('loading-data'))
                setActiveText(t('please-wait'));
            else 
                setActiveText(t('loading-data'));
        }, 7000);

        return () => {
            clearInterval(interval);
        }
    }, [activeText])

    

    return (
      <div className="main__spinner--container">
          <img 
          className='spinner-gif'
          src={loadingSpinner} 
          alt="spinner" />
          <p className='loading-info'>{activeText}...</p>
      </div>
    )
}

export default LoadingSpinner
import './reportDataCards.scss';

import { useSelector } from 'react-redux';

import SeoScoreCard from '../SeoScoreCard/SeoScoreCard';
import PreviewCard from '../PreviewCard/PreviewCard';
import CategoriesAnalys from '../CategoriesAnalys/CategorierAnalys';

const ReportDataCards = ({pageData, isMobile}) => {

    const loading = useSelector(state => state.loading);

    const isEmpty = (obj) => {
        return Object.entries(obj).length === 0;
    };
    
    const {FCP, SI, LCP, TBT, CLS, screen, m_FCP, m_SI, m_LCP, m_TBT, m_CLS, m_screen} = pageData;
    let CLS_percentage = null
    let FCP_percentage = null
    let SI_percentage = null
    let LCP_percentage = null
    let TBT_percentage = null
    let score = null;
        
    if (!isEmpty(pageData) && !loading) {
        CLS_percentage = isMobile ? (1-(m_CLS / 0.82)) * 0.25 : (1-(CLS / 0.82)) * 0.25;
        FCP_percentage = isMobile ? (1-(m_FCP / 6000)) * 0.10 : (1-(FCP / 4000)) * 0.10;
        SI_percentage = isMobile ? (1-(m_SI / 12000)) * 0.10 : (1-(SI / 5000)) * 0.10;
        LCP_percentage = isMobile ? (1-(m_LCP / 8000)) * 0.25 : (1-(LCP / 6000)) * 0.25;
        TBT_percentage = isMobile ? (1-(m_TBT / 3000)) * 0.30 : (1-(TBT / 2000)) * 0.30;
        score = Math.round((CLS_percentage + FCP_percentage + SI_percentage + LCP_percentage + TBT_percentage) * 100);
    } else {
        score = 0;
    }
    
    return ( 
        <div className="report__data--content">
            
            <SeoScoreCard 
            isEmpty={isEmpty(pageData)}
            score={score}/>

            <PreviewCard screen={screen} m_screen={m_screen}/>
            <CategoriesAnalys pageData={pageData}/>

        </div>      
    );
}
 
export default ReportDataCards;
import './serpstatChartModule.scss';

import AreaCharts from '../../Charts/AreaCharts';

import info_icon from '../../../images/icons/icons8-info (1).svg';
import no_info from '../../../images/icons/not-data-icon.png';

import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const SerpstatChartModule = ({data, id, loading}) => {

    const { t } = useTranslation();
    let modifArr;
    let minValue, maxValue;

    if (data && data.length > 0) {
        modifArr = data.map(item => (
            {
                value: item[id],
                date: item.date
            }
        ))
        maxValue = data.reduce((prev, current) => (prev && prev[id] > current[id]) ? prev : current);
        minValue = data.reduce((prev, current) => (prev && prev[id] < current[id]) ? prev : current);
    }

    const optimizeValueSize = (value) => {
        if (Math.abs(value) > 1000000000) return (value / 1000000000).toFixed(1) + 'B';
        else if (Math.abs(value) > 1000000) return (value / 1000000).toFixed(1) + 'M';
        else if (Math.abs(value) > 1000) return (value / 1000).toFixed(1) + 'K';
        else if (Math.abs(value)%1 !== 0) return value.toFixed(2);
    }

    
    return (
        <div className="history-chart--wrapper">
            <div className="history-card--header">

                <p className="history-title">{t(id + '-trend')}</p>
                <div className='history-icon'>
                    <img src={info_icon} alt="info" />
                    <p className="history-icon__msg">{t(id + '-desc')}</p>
                </div>

            </div>

            <div className="history-chart--container">
            {(data && data.length > 2) || loading ? 
                <>
                <div className="ext-points">
                    <div className="point">
                        <p className="point--title">{t('max-point')}: {data && data.length && !loading > 0 ? maxValue.date : null}</p>
                    {!loading ? 
                        <p className="point--value">{optimizeValueSize(maxValue[id])}</p>
                    : <Skeleton variant='text'/>}
                    </div>
                    <div className="point">
                        <p className="point--title">{t('min-point')}: {data && data.length && !loading > 0 ? minValue.date: null}</p>
                    {!loading ? 
                        <p className="point--value">{optimizeValueSize(minValue[id])}</p>
                    : <Skeleton variant='text'/>}
                    </div>
                </div>
                
                {!loading && data && data.length > 2 ?
                <AreaCharts data={modifArr}/>
                :<Skeleton variant='rounded' height={225}/>}
                </>

                : 
                <div className="no_data_icon">
                    <img src={no_info} alt='no data'/>
                    <p>{t('not-data')}</p>
                </div>} 
            </div>
        </div>
    )
}

export default SerpstatChartModule
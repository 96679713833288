import './seoChacker.scss';

import ReportHeader from '../../component/ReportHeader/ReportHeader';
import ReportDataCards from '../../component/ReportDataCards/ReportDataCards';
import ReportMetrix from '../../component/ReportMetrix/ReportMetrix';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import LoadingSpinner from '../../component/LoadingSpinner/LoadingSpinner';

const SeoChecker = ({
    pageData, 
    loadData, 
    emailVerification, 
    showPrices,
    handleDownloadClick
}) => {

    const loading = useSelector(state => state.loading);
    const contentRef = useRef(null);
    const contentRefMobile = useRef(null);
    const dispatch = useDispatch();
    const pathname = window.location.pathname.split('/');
    const id = pathname[2];

    const isMobile = useSelector(state => state.isMobile);
    
    
    useEffect(() => {
        !Object.keys(pageData).length && id && loadData(id);
        dispatch({type: "setDownloadContent", payload: contentRef});
        dispatch({type: "setDownloadContentMobile", payload: contentRefMobile})
    }, []);

    const wrapperClasses = loading ? "c-wrapper blur" : "c-wrapper"
    return (
        <>
        {loading  ?
            <LoadingSpinner/>
        : null}

        <div className={wrapperClasses}>
            <div className="report-container c-container">
                <ReportHeader 
                handleDownloadClick={handleDownloadClick}
                showPrices={() => showPrices()}
                pageData={pageData} 
                emailVerification={emailVerification}/>
                
                <div ref={contentRefMobile}>
                    <ReportDataCards pageData={pageData} isMobile={isMobile}/>
                    <ReportMetrix pageData={pageData} isMobile={isMobile}/>
                </div>

                <div ref={contentRef} className='ref_content_mobile hide'>
                    <ReportDataCards pageData={pageData} isMobile={!isMobile}/>
                    <ReportMetrix pageData={pageData} isMobile={!isMobile}/>
                </div>
            </div>

        </div>
        </>
    );
}
 
export default SeoChecker;
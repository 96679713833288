import SerpstatChartModule from './SerpstatChartModule/SerpstatChartModule'
import {     useSelector } from 'react-redux';

const SerpstatTrendGraphics = ({loading}) => {
  const serpstatHistoryInfo = useSelector(state => state.serpstatHistoryInfo);

  return (
    <div className="serpstat-history--charts-container">
        <SerpstatChartModule 
        data={serpstatHistoryInfo} 
        id='visible'
        loading={loading}/>
        <SerpstatChartModule 
        data={serpstatHistoryInfo} 
        id='traff'
        loading={loading}/>
    </div>
  )
}

export default SerpstatTrendGraphics
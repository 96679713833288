import './serpstatMiniCard.scss';
import React from 'react';

import { Skeleton } from '@mui/material';
import info_icon from '../../images/icons/icons8-info (1).svg';
import { useTranslation } from 'react-i18next';

const SerpstatMiniCard = ({data, loading}) => {

    let value = data?.value;
    const id = data?.id;

    const { t } = useTranslation();

    if (value) {
    if (value > 1000000) value = (value / 1000000).toFixed(1) + 'M';
    else if (value > 1000) value = (value / 1000).toFixed(1) + 'K';
    else value = value.toFixed(1);
    }



    const content = <>
    <p className='mini-card--title'>{t(`${id}`)}</p>
    {data.value !== undefined && data.value !== null ?
        <>
        <p className='mini-card--value'>{value}</p>
        </> 
        :
        <>
        <p className='mini-card--error'>{t('not-data')}</p>                    
        </>}
    </>


    return (
        <div className="mini-card--container">
            <div className="mini-card--text">

                {loading ? 
                <>
                <Skeleton variatin='text' sx={{ fontSize: '.6rem' }} width={60} animation='wave'/>
                <Skeleton variatin='text' sx={{ fontSize: '.6rem' }} width={100} animation='wave'/> 
                </>
                : content}

            </div>
            <div className='mini-card--icon'>
                <img src={info_icon} alt="info" />
                <p className="info__icon--msg">{t(`${id}-desc`)}</p>
            </div>
        </div>
    )
}

export default SerpstatMiniCard
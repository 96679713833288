import '../ReportHeader/reportHeader.scss';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';


import SkeletonIcon from '../Skeleton/SkeletonIcon';
import SkeletonText from '../Skeleton/SkeletonText';
import img_not_found from '../../images/icons/img_not_found.png';
import countries from 'i18n-iso-countries';
import { useDispatch, useSelector } from 'react-redux';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const SerpstatHeader = ({ pageData, loading, loadSerpstatInfo }) => {
    const { t } = useTranslation();
    const country = useSelector(state => state.serpstatCountry);
    const dispatch = useDispatch();
    const link = useSelector(state => state.link);

    const countryOptions = Object.keys(countries.getNames('en')).map(countryCode => ({
        value: countryCode,
        label: countries.getName(countryCode, 'en'),
    }));

    const handleCountryChange = (selected) => {
        if (selected.value !== country) {
            dispatch({ type: "setSerpstatCountry", payload: selected });
            const se = selected?.value?.toLowerCase();
            loadSerpstatInfo(link, se);
        }
    }

    let {icon, url, title} = pageData;
    icon = icon ? icon : img_not_found;

    return (
            <div className='report__row d-flex flex-row serpstat__header'>
                
                <div className="report__main-info d-flex">
                    {loading ? <SkeletonIcon /> :
                    <div className="report__web-icon">
                        <img src={icon} alt="icon" />
                    </div>
                    }
                    <div className="report__web-text">
                        {loading ? <SkeletonText/> : 
                        <>
                            <div className="report__web--title">{title}</div>
                            <a className="report__web--link" href={url} target='_blanc'>{url}</a>
                        </>}
                    </div>
                </div>

                {/* <p className='issues__title'>{t('summary-info')}</p> */}
                <div className='country-selector--container'>
                    <label>{t("select-country")}</label>
                    <Select 
                        options={countryOptions}
                        value={country}
                        defaultInputValue={country.label}
                        defaultValue={country}
                        onChange={handleCountryChange}
                    />
                </div>


            </div>
    );
}
 
export default SerpstatHeader;
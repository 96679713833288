import React, { useEffect, useRef, useState } from 'react'
import './inputMailPopup.scss';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CookieService } from '../../services/CookieService';
import { useTranslation } from 'react-i18next';
import close_button from '../../images/icons/close_button.png';



const InputMailPopup = ({ handleDownloadClick }) => {
    const [className, setClassName] = useState('input-mail--wrapper');
    const [inputValue, setInputValue] = useState("");
    const [isInvalidMail, setIsInvalidMail] = useState(false);
    const { t } = useTranslation();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const cookieService = new CookieService();

    formRef.current && formRef.current.focus();
    useEffect(() => {
        setClassName('input-mail--wrapper active');
        window.addEventListener('keydown', handleKey);

        return () => document.removeEventListener('keydown', handleKey);
    }, [])

    const emailValueVerification = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(inputValue)) {
            setIsInvalidMail(false);
            activateEmail(inputValue);
        }
        else if (inputValue) {
            setIsInvalidMail(true);
        }
    }

    const handleKey = (key) => {
        if (key.key === "Escape") unshowPopup();
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        emailValueVerification();
    }

    const handleEmailChange = (event) => {
        setInputValue(event.target.value);
    };

    const activateEmail = (email) => {
        cookieService.setCookie('userEmail', email, 1);
        dispatch({type: "setEmail", pyload: email});
        handleDownloadClick();
    }

    const unshowPopup = () => {
        dispatch({type: "showEmailInput", payload: false});
    }


    return (
        <>
            
            <div className={className}>

                <button 
                    onClick={unshowPopup}
                    className='close-button'>
                        <img src={close_button} alt="close tarifs"/>
                </button>


                <div className="input-mail--text">
                    <p>{t('input-mail-message')}</p>
                </div>
                <div className="input-form">
                    <Form 
                    onSubmit={handleFormSubmit}>
                    <Form.Group className="mb-3" >
                        <Form.Control 
                        ref={formRef}
                        type="email" 
                        placeholder="name@example.com" 
                        onChange={handleEmailChange}/>
                    </Form.Group>
                    </Form>
                    <Button
                    onClick={emailValueVerification} 
                    variant="primary" 
                    size="sm">
                        {t('enter')}
                    </Button>
                </div>
                
                {isInvalidMail ? <div className='invalid-mail--message'>
                    <p>Invalid mail</p>
                </div> : null}
            </div>
        <div className="input-mail--background">
        </div>
        </>
    )
}

export default InputMailPopup
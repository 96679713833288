import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default class AreaCharts extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/area-chart-connect-nulls-mdh0c';




  data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 190,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page E',
      uv: 3890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 5390,
      pv: 3800,
      amt: 2500,
    },
  ];

  render() {

    const { data } = this.props;

    return (
      <div>

        <ResponsiveContainer width="100%" height={200}>
          <AreaChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Area connectNulls type="monotone" dataKey="value" stroke="#0d6efd" fill="#0d6efd" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

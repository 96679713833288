import React, { useEffect } from 'react'
import './pageNotFound.scss';

const PageNotFound = () => {

    useEffect(() => {
        fetch('https://seo.extensive.digital:8443/errorpage');
    }, [])


  return (
    <div className='page-not-found--wrapper'>
        <div className='page-not-found--container'>
            <p className='page-not-found--code'>404</p>
            <p className='page-not-found--msg'>Page not found</p>
        </div>
    </div>
  )
}

export default PageNotFound
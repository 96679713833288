import { getCurrentDateTime } from "../component/helper/getCurrentDataTime";
import { CookieService } from "./CookieService";


export const postActionData = async (dataRequest, action, token, email, validatedURL) => {

    const cookieService = new CookieService();
    let userAction = undefined;
    if (action === 'request') userAction = `request page speed data: ${dataRequest.result.url}`;
    else if (action === 'download-report') userAction = 'download report';

    try {
      const ipResponse = await fetch('https://ipinfo.io/json?token=3858d2d8d087f5');
      const ipData = await ipResponse.json();
      const access_token = await cookieService.getCookie("user-token");
      const userIP = ipData.ip;
      const userCountry = ipData.country;

      let reqMessage = '';

      if (dataRequest.status >= 200 && dataRequest.status < 300) reqMessage = 'success';
      else if (dataRequest.status >= 300 && dataRequest.status < 400) reqMessage = 'redirect';
      else if (dataRequest.status === 408) reqMessage = 'request timeout';
      else if (dataRequest.status >= 400 && dataRequest.status < 500) reqMessage = 'client error';
      else if (dataRequest.status >= 500 && dataRequest.status < 600) reqMessage = 'server error';
      const data = {
        userIP,
        userCountry,
        userAction,
        state: 'hello',
        apiIntegration: {
          responseStatus: dataRequest?.status,
          responseStaturMobile: dataRequest?.mobileStatus,
          dataRequest,
          message: reqMessage
        },
        timestamp: getCurrentDateTime(),
        reqUrl: validatedURL,
        email,
        token: token,
        access_token 
      };

  
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}:8443/save-session`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        console.log("Error send session info");
      } 
    } catch (error) {
      console.error('Error send to DB:', error);
    }
  };
import './backlinksCard.scss';

import BacklinksItemSDR from './BacklinksItem/BacklinksItemSDR';
import BacklinksItemLinks from './BacklinksItem/BacklinksItemLinks';

import { useTranslation } from 'react-i18next';
import info_icon from '../../images/icons/icons8-info (1).svg';
import { useSelector } from 'react-redux';

const BacklinksCard = ({loading}) => {
    
    const { t } = useTranslation();
    const serpstatBacklinksInfo = useSelector(state => state.serpstatBacklinksInfo);

    const cardsData = [
        [
            {
                id: 'refering-domains',
                value: serpstatBacklinksInfo?.referring_domains,
                change_value: serpstatBacklinksInfo?.referring_domains_change,
            },
            {
                id: 'redirecting-domains',
                value: serpstatBacklinksInfo?.redirected_domains,
            },
            {
                id: 'malicious-domain',
                value: serpstatBacklinksInfo?.referring_malicious_domains,
                change_value: serpstatBacklinksInfo?.referring_malicious_domains_change,
            },
            {
                id: 'ip-subnets',
                value: serpstatBacklinksInfo?.referring_subnets,
                change_value: serpstatBacklinksInfo?.referring_subnets_change,
            }
        ],
        [
            {
                id: 'backlinks',
                value: serpstatBacklinksInfo?.backlinks,
                change_value: serpstatBacklinksInfo?.backlinks_change,
            },
            {
                id: 'from-main-pages',
                value: serpstatBacklinksInfo?.backlinks_from_mainpages,
                change_value: serpstatBacklinksInfo?.backlinks_from_mainpages_change,
            },
            {
                id: 'follow-backlinks',
                value: serpstatBacklinksInfo?.dofollow_backlinks,
                change_value: serpstatBacklinksInfo?.dofollow_backlinks_change,
            },
            {
                id: 'nofollow-backlinks',
                value: serpstatBacklinksInfo?.nofollow_backlinks,
                change_value: serpstatBacklinksInfo?.nofollow_backlinks_change,
            }
        ],
        [
            {
                id: 'external-domains',
                value: serpstatBacklinksInfo?.external_domains,
                change_value: serpstatBacklinksInfo?.external_domains_change,
            },
            {
                id: 'malicious',
                value: serpstatBacklinksInfo?.external_malicious_domains,
                change_value: serpstatBacklinksInfo?.external_malicious_domains_change,
            },
            {
                id: 'external-links',
                value: serpstatBacklinksInfo?.external_links,
                change_value: serpstatBacklinksInfo?.external_links_change,
            }
        ],
    ]



    return (
        <div className="backlinks-card--wrapper">
            <div className="backlinks-card--header">
                <p className="backlinks-title">{t('backlinks-overview')}</p>
                <div className='backlinks-icon'>
                    <img src={info_icon} alt="info" />
                    <p className="backlinks-icon__msg">{t('backlinks-overview-desc')}</p>
                </div>
            </div>

            <div className="backlinks-card--container">
                <BacklinksItemSDR 
                sdrValue={serpstatBacklinksInfo?.sersptat_domain_rank} 
                loading={loading}/>

                {cardsData.map((rowData, key) => (
                    <BacklinksItemLinks 
                    key={key} 
                    rowData={rowData} 
                    loading={loading}/>
                ))}

            </div>
        </div>
    )
}

export default BacklinksCard
import './header.scss';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../helper/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import check_icon from '../../images/icons/check-mark-10120.svg';
import copy_icon from '../../images/icons/copy.svg';
import seolitte_icon from '../../images/icons/seolitte_icon.png';

import { Dropdown } from 'react-bootstrap';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Form, Button } from 'react-bootstrap';
import ErrorPopup from '../ErrorPopup/ErrorPopup';


const InputComponent = ({onSubmit, errorMsg}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const language = useSelector(state => state.language);
    const loading = useSelector(state => state.loading);
    const page = useSelector(state => state.currentPage);
    const dispatch = useDispatch();
    const [, setLang] = useLocalStorage('lang');
    const {t, i18n} = useTranslation();
    const token = useSelector(state => state.token);
    const [isCopy, setIsCopy] = useState(false);
    const inputRef = useRef();

    const currentPath = location.pathname.split('/')[1];
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDowm);
        return () => document.removeEventListener('keydown', handleKeyDowm);
    })

    const handleKeyDowm = (event) => {
        if (event.key === 'Enter' && !loading) {
            onSendLink();
        }
    }

    const onSendLink = () => {
        if (currentPath && inputRef.current.value) {
            onSubmit(inputRef.current.value)
        }
    }

    const CustomSpinner = () => {
        return (
            <MDBSpinner role='status' style={{
                width: 15,
                height: 15
            }}>
            <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        )
    }

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLang(lang);
        dispatch({type: 'language', payload: lang});
        
    }


    const onCopy = async () => {
        const textArea = document.createElement("textarea");
        const url = `${process.env.REACT_APP_PAGEDOMAIN}/base/${token}`
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        setIsCopy(true);

        setTimeout(() => {
            setIsCopy(false);
        }, 10000)

    }

    const copyIcon = isCopy ? check_icon : copy_icon;

    return (

        <div className='fixed__container'>
        <div className="header__wrapper">
            <div className="c-container">

                <div 
                    onClick={loading ? null : () => {
                        navigate('/');
                    }}
                    className='header__title'>
                        <img className='header__icon' src={seolitte_icon} alt="icon" />
                        <span className='title'>Seolitte Automation Online Tool</span>
                </div>
                

                {currentPath ? 
                <>
                <div className="input__form">
                    <Form.Control 
                    ref={inputRef}
                    className='input__component--form'
                    size="sm" 
                    type="text" 
                    placeholder={t('input-placeholder')} />
                    {errorMsg ? <p className="error__input--text">{errorMsg}</p> : null}
                </div>
                <Button 
                disabled={loading}
                className='input__component--button'
                onClick={() => onSendLink()}
                size='sm'
                variant="primary">
                    
                    {!loading ? <>{t('check-seo')}</> : null}
                    {loading ? <CustomSpinner/> : null}
                
                </Button> 
                </>: null
                }
                {errorMsg ? <ErrorPopup errorMsg={errorMsg}/> : null}


                <Dropdown>
                    <Dropdown.Toggle variant='' id="dropdown-basic">
                        <p className='language__icon' alt="language icon">{language}</p>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item 
                        style={{color: language === "en" ? 'blue' : ''}}
                        onClick={() => changeLanguage("en")}>EN ({t('eng')})</Dropdown.Item>
                        <Dropdown.Item 
                        style={{color: language === "ro" ? 'blue' : ''}}
                        onClick={() => changeLanguage("ro")}>RO ({t('rom')})</Dropdown.Item>
                        <Dropdown.Item 
                        style={{color: language === "ru" ? 'blue' : ''}}
                        onClick={() => changeLanguage("ru")}>RU ({t('rus')})</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            </div>
            
            {/* navbar */}
            {currentPath === 'base' ? 
            <div className="navbar">
                <div className="navbar__container">
                    <div className="navbar__links">

                        <div 
                        onClick={() => dispatch({type: "setPage", payload: 'base'})}
                        className={page === 'base' ? 'page__link active-page' : 'page__link'}>{t('base')}</div>

                        <div 
                        onClick={() => dispatch({type: "setPage", payload: 'issues'})}
                        className={page === 'issues' ? 'page__link active-page' : 'page__link'}>{t('top-issues')}</div>

                        <div 
                        onClick={() => dispatch({type: "setPage", payload: 'serpstat'})}
                        className={page === 'serpstat' ? 'page__link active-page' : 'page__link'}>Keywords Overview</div>

                        {token && !loading ? <button 
                        className="copy-button"
                        onClick={onCopy}>
                            <img src={copyIcon} alt="copy to clipboard"/>
                            <span>{t('copy-share-link')}</span>
                        </button> : null}
                    </div>
                </div>
            </div>
            : null}

        </div>
    );
}
 
export default InputComponent;
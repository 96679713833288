import './keywordsCard.scss';
import { useEffect } from 'react';

import info_icon from '../../images/icons/icons8-info (1).svg';
import not_data_icon from '../../images/icons/not-data-icon.png';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';


const KeywordsCard = ({items, loading, showPrices}) => {

  const { t } = useTranslation();
  const serpstatKeywordsInfo = useSelector(state => state.serpstatKeywordsInfo);
  
  useEffect(() => {
    Array.isArray(serpstatKeywordsInfo) && serpstatKeywordsInfo?.forEach((item) => {
      if (item) {
      if (item.region_queries_count > 1000000) item.region_queries_count = (item.region_queries_count / 1000000).toFixed(1) + 'M';
      else if (item.region_queries_count > 1000) item.region_queries_count = (item.region_queries_count / 1000).toFixed(1) + 'K';

        if(item && typeof item.cost === 'number') item.cost = item?.cost?.toFixed(3);
      }
    })
  })

  //transform value
  items.forEach((item) => {
    if (item.value) {
      if (item.value > 1000000) item.value = (item.value / 1000000).toFixed(1) + 'M';
      else if (item.value > 1000) item.value = (item.value / 1000).toFixed(1) + 'K';
    }
  })



  return (
    <div className='keywords-card--wrapper'>
      <div className="keywords-card--header">

        <p className="keywords-title">{t('organic-keywords')}</p>
        <div className='keywords-icon'>
            <img src={info_icon} alt="info" />
            <p className="keywords-icon__msg">{t('organic-keywords-desc')}</p>
        </div>

      </div>
      <div className='keywords-card--container keywords--container'>


            {serpstatKeywordsInfo?.length || loading ? 
            <>
              <div className="keywords-content--header">
                  {
                  items.map((item, key) => (
                    loading ? <Skeleton variant="rounded" /> :
                      <div key={key} className='keywords-content--item'>
                        <p className='keywords-content--title'>{item.id}</p>
                        <p className='keywords-content--value'>{item.value}</p>
                      </div>
                  
                  )) 
                  } 
              </div>

              <table className="keywords-content--table">
                  <thead>
                    <tr>
                      <th style={{width: '50%', textAlign: 'left'}}>{loading ? <Skeleton variant="text" /> : t('keyword')}</th>
                      <th>{loading ? <Skeleton variant="text" /> : t('position')}</th>
                      <th>{loading ? <Skeleton variant="text" /> : t('volume')}</th>
                      <th>{loading ? <Skeleton variant="text" /> : 'CPC($)'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? serpstatKeywordsInfo?.map((word, key) => (
                      <tr 
                      key={key}
                      style={key%2!==0 ? 
                      {backgroundColor: 'white'} : 
                      {backgroundColor: 'rgb(246, 246, 246)'}}>
                        <td style={{textAlign: 'left'}}>{word.keyword}</td>
                        <td>{word.position}</td>
                        <td>{word.region_queries_count}</td>
                        <td>{word.cost}</td>
                      </tr>
                    )) :
                    null}
                    </tbody>
              </table>
              <Button 
              className='show-traff--button'
              onClick={() => showPrices("moreKeywords")}
              variant="primary" 
              size="sm"> 
                {t('view-more-keywords')}
              </Button>
              </>
              : 
              <div className="not_data_icon">
                <img src={not_data_icon} alt='no data'/>
                <p>{t('not-data')}</p>
              </div>
            }
          </div>
    </div>
  )
}

export default KeywordsCard
import React, { useEffect, useState } from 'react'
import './settingPage.scss';

const SettingPage = ({ showSuccessPopup }) => {
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(undefined);
    const [serpstat, setSerpstat] = useState(undefined);
    const [google, setGoogle] = useState(undefined);
    const [cacheRequest, setCacheRequest] = useState(undefined);
    const [abortedTimeout, setAbortedTimeout] = useState(undefined);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-request-limit`)
        .then(response => response.json())
        .then(data => {
            setLimit(data.result);
        });

        fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-google-page-speed-api-key`)
        .then(response => response.json())
        .then(data => {
            setGoogle(data.response);
        });


        fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-serpstat-api-key`)
        .then(response => response.json())
        .then(data => {
            setSerpstat(data.response);
        });

        fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-cache-request`)
        .then(response => response.json())
        .then(data => {
            setCacheRequest(data.response);
        });

        fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-aborted-timeout`)
        .then(response => response.json())
        .then(data => {
            setAbortedTimeout(data.response / 1000);
        });

    }, []);



    const handleSubmitReqLimit = async (e) => {
        setLoading(true);
        e.preventDefault();
        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/update-request-limit`,  {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({requestLimit: limit}),
        })
        .then(response => {
            showSuccessPopup();
            setLoading(false);
            response.json()
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    const handleSubmitGoogle = async (e) => {
        setLoading(true);
        e.preventDefault();
        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/update-google-page-speed-key`,  {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({apiKey: google}),
        })
        .then(response => {
            showSuccessPopup();
            setLoading(false);
            response.json()
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }


    const handleSubmitSerpstat = async (e) => {
        setLoading(true);
        e.preventDefault();
        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/update-serpstat-key`,  {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({apiKey: serpstat}),
        })
        .then(response => {
            showSuccessPopup();
            setLoading(false);
            response.json()
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    const handleSubmitChangeCacheRequest = async (e) => {
        setLoading(true);
        e.preventDefault();
        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/update-cache-request`,  {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({cache_request: cacheRequest}),
        })
        .then(response => {
            showSuccessPopup();
            setLoading(false);
            response.json()
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }

    const handleSubmitAbortedTimeout = async (e) => {
        setLoading(true);
        e.preventDefault();
        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/update-aborted-timeout`,  {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({timeout: (abortedTimeout * 1000)}),
        })
        .then(response => {
            showSuccessPopup();
            setLoading(false);
            response.json()
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }


    const handleChangeReqLimit = (e) => {
        setLimit(e.currentTarget.value);
    }

    const handleChangeSerpstatKey = (e) => {
        setSerpstat(e.currentTarget.value);
    }

    const handleChangeGoogleKey = (e) => {
        setGoogle(e.currentTarget.value);
    }

    const handleChangeCacheRequest = (e) => {
        setCacheRequest(e.currentTarget.value)
    }

    const handleChangeAbortedTimeout = (e) => {
        setAbortedTimeout(e.currentTarget.value);
    }

    return (
        <div className='admin-wrapper'>
            <div className="c-container">
                <form
                className='form-request-limit'
                onSubmit={loading ? null : handleSubmitSerpstat}>
                    <label for="points">Serpstat Api Key: </label>
                    <div>
                        <input 
                        onChange={handleChangeSerpstatKey}
                        type="string" 
                        id="points" 
                        name="points" 
                        step="1" 
                        value={serpstat}/>
                        <input type='submit' value="Apply"></input>
                    </div>
                </form>

                <form
                className='form-request-limit'
                onSubmit={loading ? null : handleSubmitGoogle}>
                    <label for="points">Google Page Speed Api Key: </label>
                    <div>
                        <input 
                        onChange={handleChangeGoogleKey}
                        type="string" 
                        id="points" 
                        name="points" 
                        step="1" 
                        value={google}/>
                        <input type='submit' value="Apply"></input>
                    </div>
                </form>

                <form
                className='form-request-limit'
                onSubmit={loading ? null : handleSubmitReqLimit}>
                    <label for="points">Free Requests Limit: </label>
                    <div>
                        <input 
                        onChange={handleChangeReqLimit}
                        type="number" 
                        id="points" 
                        name="points" 
                        step="1" 
                        value={limit}/>
                        <input type='submit' value="Apply"></input>
                    </div>
                </form>
                
                <form
                className='form-request-limit'
                onSubmit={loading ? null : handleSubmitChangeCacheRequest}>
                    <label for="points">Cache Request Interval (minute): </label>
                    <div>
                        <input 
                        onChange={handleChangeCacheRequest}
                        type="number" 
                        id="points" 
                        name="points" 
                        step="1" 
                        value={cacheRequest}/>
                        <input type='submit' value="Apply"></input>
                    </div>
                </form>

                <form
                className='form-request-limit'
                onSubmit={loading ? null : handleSubmitAbortedTimeout}>
                    <label for="points">Aborted Timeout (sec): </label>
                    <div>
                        <input 
                        onChange={handleChangeAbortedTimeout}
                        type="number" 
                        id="points" 
                        name="points" 
                        step="1" 
                        value={abortedTimeout}/>
                        <input type='submit' value="Apply"></input>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default SettingPage
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import App from './App';
import { Provider, } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminPanel from './component/AdminPanel/AdminPanel';

import './18n';

const defaultState = {
  link: false,
  loading: false,
  language: "ro",
  isMobile: true,
  currentPage: 'base',
  downloadContent: null,
  meta: "",
  downloadContentMobile: null,
  allActive: false,
  token: null,
  serpstatData: {},
  serpstatCardsInfo: null,
  serpstatDistribInfo: null,
  serpstatKeywordsInfo: null,
  serpstatHistoryInfo: null,
  serpstatBacklinksInfo: null,
  email: null,
  showEmailInput: false,
  countryDomain: "md",
  serpstatCountry: {
    value: "MD", 
    label: "Moldova, Republic of" 
  }
}


const reducer = (state = defaultState, action) => {
    switch(action.type) {
      case "isLoading":
        return {...state, loading: true}
      case "isNotLoading":
        return {...state, loading: false}
      case "language":
        return {...state, language: action.payload}
      case "isMobile":
        return {...state, isMobile: true}
      case "isNotMobile": 
        return {...state, isMobile: false} 
      case "link":
        return {...state, link: action.payload}
      case "setPage":
        return {...state, currentPage: action.payload}
      case "setDownloadContent":
        return {...state, downloadContent: action.payload}
      case "setDownloadContentMobile":
        return {...state, downloadContentMobile: action.payload}
      case "setAllActive":
        return {...state, allActive: action.payload}
      case "setRequestToken":
        return {...state, token: action.payload}
      case "setSerpstatData":
        return {...state, serpstatData: action.payload}
      case "setSerpstatDistribInfo":
        return {...state, serpstatDistribInfo: action.payload}
      case "setSerpstatCardsInfo":
        return {...state, serpstatCardsInfo: action.payload}
      case "setSerpstatKeywordsInfo":
        return {...state, serpstatKeywordsInfo: action.payload}
      case "setSerpstatHistoryInfo":
        return {...state, serpstatHistoryInfo: action.payload}
      case "setSerpstatBacklinksInfo":
        return {...state, serpstatBacklinksInfo: action.payload}
      case "setEmail":
        return {...state, email: action.payload}
      case "showEmailInput":
        return {...state, showEmailInput: action.payload}
      case "setCountryDomain":
        return {...state, countryDomain: action.payload}
      case "setSerpstatCountry": 
        return {...state, serpstatCountry: action.payload}
      default: 
        return state;
    }
}


const store = createStore(reducer);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
    <Provider store={store}>
      <Routes>
        <Route path='/admin' element = {<AdminPanel />} />
        <Route path='*' element={<App />} />
        
      </Routes>
      
    </Provider>
    </Suspense>
  </BrowserRouter>
);

import './circluarProgressBar.scss';
import React from 'react';

const CircularProgressBar = ({ percentage = 0 }) => {

    let progressColor;
    let classProgressBar = "circular-progress-bar";
    let isNegativ = percentage < 0 ? true : false;
    
    percentage = percentage > 100 ? 100 : percentage;

    
    if (percentage <= 25) {
        progressColor = 'rgb(255, 70, 70)';
        classProgressBar += ' percentage25';
    }
    else if (percentage <= 50) {
        progressColor = 'orange';
        classProgressBar += ' percentage50';
    }
    else if (percentage <= 75) {
        progressColor = 'rgb(214, 214, 0)';
        classProgressBar += ' percentage75';
    }
    else if (percentage <= 100) {
        progressColor = 'rgb(3, 191, 3)';
        classProgressBar += ' percentage100';
    }


    const dashOffset = isNegativ ? 1100 : 1100 - 11 * percentage;

    return (
        <>
        <div class={classProgressBar}>
            <svg width="100%" height="100%" viewBox="-25 -25 400 400">
                <circle stroke="#ecedf0" cx="175" cy="175" r="175" stroke-width="50" fill="none"></circle>
                <circle 
                stroke={progressColor} 
                transform="rotate(-90 175 175)" cx="175" cy="175" r="175" 
                stroke-dasharray="1100" 
                stroke-width="50" 
                stroke-dashoffset="1100" 
                stroke-linecap="butt" 
                fill="none" 
                style={{strokeDashoffset: dashOffset, 
                transition: "stroke-dashoffset 1s ease-out 0s"}}></circle>

                {isNegativ ?
                <text fill="rgb(255, 70, 70)" x="50%" y="50%" dx="-25" dy="35" text-anchor="middle" 
                style={{font: "bold 12rem Helvetica, Arial, sans-serif"}}>
                    !
                </text>
                :
                <text fill="gray" x="50%" y="50%" dx="-25" text-anchor="middle" 
                style={{font: "bold 4rem Helvetica, Arial, sans-serif"}}>
                    {percentage}
                </text>
                }
            </svg>
        </div>
        </>

        


        
    );
};

export default CircularProgressBar;

import React from 'react'
import close_button from '../../images/icons/close_button.png';
import { DateRangePicker } from 'react-date-range';


const RangePopup = ({ handleSelect, unshowCalendar, selectionRange }) => {
    return (
        <div className='calendar__wrapper'>
            <img
            src={close_button} 
            alt="close calendar" 
            onClick={unshowCalendar}/>
            <div className="calendar-container">
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                />
            </div>
        </div>
    )
}

export default RangePopup
import React, { useEffect, useState } from 'react'

const SuccessPopup = ({message = "Success!"}) => {
    const [className, setClassName] = useState('success-popup--wrapper');

    useEffect(() => {
        setTimeout(() => {
            setClassName('success-popup--wrapper success-popup--active');
        }, 2500)
    }, []);


    return (
        <div className={className}>
            <p className='success-popup--text'>{message}</p>
        </div>
    )
}

export default SuccessPopup
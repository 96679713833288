
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

import watermark from '../../images/watermark_seolitte_full.png';

import { getCurrentDateTime } from './getCurrentDataTime';
import html2canvas from 'html2canvas';



const downloadPdf = async (content, m_content, fileName, isMobile, pageData, lang, serpstatDataObj, token) => {
    const {LShiftData, cacheData, renderBlockingResourse, unusedCSS, redirectsInfo, criticalRequestChains, unusedJS} = pageData;
    const dataIssues = [LShiftData, cacheData, renderBlockingResourse, unusedCSS, redirectsInfo, criticalRequestChains, unusedJS];

    let dateTimeObj = null;
    let dateTime = null;

    await fetch(`${process.env.REACT_APP_DOMAIN}:8443/getSavedData?token=${token}`)
    .then(response => response.json())
    .then(response => {
        dateTimeObj = new Date(response?.dateTime);
    })
    

    if (dateTimeObj) {
        let year = dateTimeObj.getUTCFullYear();
        let mounth = dateTimeObj.getUTCMonth() + 1;
        let day = dateTimeObj.getUTCDate();
        let hr = dateTimeObj.getUTCHours();
        let min = dateTimeObj.getUTCMinutes();
        let sec = dateTimeObj.getUTCSeconds();

        dateTime = year + "-" + mounth + "-" + day + " " + hr + ":" + min + ":" + sec;
    }


    const {serpstatCardsInfo, serpstatKeywordsInfo, serpstatDistribInfo, serpstatBacklinksInfo} = serpstatDataObj;

    const pdf = new jsPDF({
        encoding: 'utf-8'
    });

    const localization = await fetch(`${process.env.REACT_APP_DOMAIN}:8443/locales/${lang}/translation.json`).then(response => response.json());
    const font = require('./Roboto-Medium.ttf');

    pdf.addFont(font, "Muli-Semi-Bold", "Semi-Bold");
    pdf.setFont("Muli-Semi-Bold", "Semi-Bold");
    let mobileContent;
    let desktopContent; 

    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width - 40;


    document.body.classList.add("fixed-width");
    document.querySelector('.ref_content_mobile').classList.remove('hide');

    html2canvas(content?.current)
    .then((canvas) => desktopContent = canvas.toDataURL('img/png'));  

    html2canvas(m_content?.current)
    .then((canvas) => mobileContent = canvas.toDataURL('img/png'));

    html2canvas(content?.current).then((canvas) => {

    const pdfWidth = 200;
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    
    const x = (pdf.internal.pageSize.getWidth() - pdfWidth) / 2;

    pdf.text(10, 10, fileName);
    pdf.setFontSize(12);
    pdf.text(160, 10, dateTime ? dateTime : getCurrentDateTime())
    pdf.text(10, 20, localization['mobile-metrix']);

    
    pdf.addImage(isMobile ? mobileContent : desktopContent, 'JPEG', x, 12, pdfWidth, pdfHeight);    
    pdf.addImage(watermark, 'JPEG', 80, 260, 45, 28);

    pdf.setTextColor(128, 128, 128);
    pdf.text(160, 283, "info@seolitte.com");
    pdf.text(160, 289, "069-809-235");
    pdf.setTextColor(0, 0, 0);


    pdf.addPage();

    pdf.text(10, 10, localization['desktop-metrix']);
    pdf.addImage(isMobile ? desktopContent : mobileContent, 'JPEG', x, 12, pdfWidth, pdfHeight);

    //serpstat data
    if (serpstatBacklinksInfo || serpstatCardsInfo || serpstatDistribInfo || serpstatKeywordsInfo) {
        pdf.addImage(watermark, 'JPEG', 80, 260, 45, 28);

        pdf.setTextColor(128, 128, 128);
        pdf.text(160, 283, "info@seolitte.com");
        pdf.text(160, 289, "069-809-235");
        pdf.setTextColor(0, 0, 0);

        pdf.addPage();

        pdf.setTextColor(80, 80, 80);
    }

    //cards info
    if (serpstatCardsInfo) {
        pdf.text(14, 10, localization['summary-info']);
        autoTable(pdf, {
            head: [[localization['visible'], localization['traff'], localization['organic-keywords'], localization['ad_keywords']]],
            body: [[serpstatCardsInfo.visible, serpstatCardsInfo.traff, serpstatCardsInfo.keywords, serpstatCardsInfo.ad_keywords]],
            styles: { 
                font: "Muli-Semi-Bold",
            },
            headStyles: {
                fillColor: '#0d6efd'
            }
        })
    }

    autoTable(pdf, {})

    //keywords Summary
    if (serpstatCardsInfo && serpstatKeywordsInfo && Array.isArray(serpstatKeywordsInfo) && serpstatKeywordsInfo.length > 0) {
        pdf.text(14, 40, localization['organic-keywords']);
        autoTable(pdf, {
            head: [[localization['new'], localization['lost'], localization['improved'], localization['declined']]],
            body: [[serpstatCardsInfo.new_keywords, serpstatCardsInfo.out_keywords, serpstatCardsInfo.rised_keywords, serpstatCardsInfo.down_keywords]],
            styles: { 
                font: "Muli-Semi-Bold",
            },
            headStyles: {
                fillColor: '#0d6efd'
            }
        })

    //organic keywords
        autoTable(pdf, {
            head: [[localization['organic-keywords'], localization['position'], localization['volume'], 'CPC($)']],
            body: serpstatKeywordsInfo.map((item) => {
                const modifiedField = [];
                modifiedField.push(item.keyword);
                modifiedField.push(item.position);
                modifiedField.push(item.region_queries_count);
                modifiedField.push(item.cost);
                return modifiedField;
            }),
            styles: { 
                font: "Muli-Semi-Bold",
            },
            headStyles: {
                fillColor: '#0d6efd'
            }
        })

        autoTable(pdf, {})
    }

    //keywords distribution
    if (serpstatDistribInfo && Array.isArray(serpstatDistribInfo)) {
        pdf.text(14, 130, localization['keywords-position-distribution']);
        autoTable(pdf, {
            head: [serpstatDistribInfo.map(item => item.name)],
            body: [serpstatDistribInfo.map(item => item.keywords)],
            styles: { 
                font: "Muli-Semi-Bold",
            },
            headStyles: {
                fillColor: '#0d6efd'
            }
        })

        autoTable(pdf, {})
    }

    //backlinks
    if (serpstatBacklinksInfo) {
        pdf.text(14, 158, localization['backlinks-overview']);
        autoTable(pdf, {
            body: [
                ['SDR', serpstatBacklinksInfo.sersptat_domain_rank], 
                [localization['backlinks'], serpstatBacklinksInfo.backlinks], 
                [localization['from-main-pages'], serpstatBacklinksInfo.backlinks_from_mainpages], 
                [localization['follow-backlinks'], serpstatBacklinksInfo.dofollow_backlinks],
                [localization['nofollow-backlinks'], serpstatBacklinksInfo.nofollow_backlinks]
            ],
            styles: { 
                font: "Muli-Semi-Bold",
            },
        })

        //ref domain
        autoTable(pdf, {
            body: [
                [localization['refering-domains'], serpstatBacklinksInfo.referring_domains], 
                [localization['redirecting-domains'], serpstatBacklinksInfo.redirected_domains], 
                [localization['malicious-domain'], serpstatBacklinksInfo.referring_malicious_domains], 
                [localization['ip-subnets'], serpstatBacklinksInfo.referring_subnets],
            ],
            styles: { 
                font: "Muli-Semi-Bold",
            },
        })

        //ext domains
        autoTable(pdf, {
            body: [
                [localization['external-domains'], serpstatBacklinksInfo.external_domains], 
                [localization['malicious'], serpstatBacklinksInfo.external_malicious_domains], 
                [localization['external-links'], serpstatBacklinksInfo.external_links], 
            ],
            styles: { 
                font: "Muli-Semi-Bold",
            },
        })
    }






    pdf.setTextColor(0, 0, 0);





    pdf.addImage(watermark, 'JPEG', 80, 260, 45, 28);

    pdf.setTextColor(128, 128, 128);
    pdf.text(160, 283, "info@seolitte.com");
    pdf.text(160, 289, "069-809-235");
    pdf.setTextColor(0, 0, 0);

    pdf.addPage();

    pdf.text(10, 10, localization['top-issues']);

    let yPos = 20;
    dataIssues.forEach((item) => {
        pdf.setTextColor(0, 0, 0);
        if (item.id !== 'critical-request-chains') {
            if (item.details.items.length !== 0) {
                pdf.setFontSize(12); 
                pdf.text(item.id + ':', 10, yPos);
                yPos += 8;
                pdf.setFontSize(10);
                const description = localization[`${item.id}-desc`];
                const line = pdf.splitTextToSize(description.split('.')[0], pageWidth - 20)
                line.forEach((ln) => {
                    pdf.text(ln, 10, yPos)
                    yPos += 5;
                })
                yPos -= 5;


                if(item.details && item.details.items) {
                    
                    pdf.setFontSize(9); 
                    item.details.items.forEach((itm) => {
                        pdf.setTextColor(13, 110, 225);
                        const textLines = pdf.splitTextToSize(item.id !== 'layout-shift-elements' ? 
                        itm.url : itm.node.snippet, pageWidth - 20); 

                        if (item.id === "layout-shift-elements")
                            textLines[textLines.length - 1] +=`    |  score: ${(itm.score).toFixed(5)}`;
                        else
                            textLines[textLines.length - 1] +=`    |  total bytes: ${itm.totalBytes}`;

                        textLines.forEach((line) => {
                            yPos += 6; 
                            pdf.textWithLink(line, 15, yPos, {
                                url: itm.url,
                                color: [13, 110, 255], 
                                underline: true 
                            });

                            if (yPos + 40 >= pageHeight) {
                                pdf.addImage(watermark, 'JPEG', 80, 260, 45, 28);

                                pdf.setTextColor(128, 128, 128);
                                pdf.text(160, 283, "info@seolitte.com");
                                pdf.text(160, 289, "069-809-235");
                                pdf.setTextColor(0, 0, 0);

                                pdf.addPage();
                                yPos = 10;
                            }
                        });
                    })

                }
                yPos += 10;
            }
        }
    })


    pdf.setTextColor(128, 128, 128);
    pdf.setFontSize(12); 
    pdf.text(160, 283, "info@seolitte.com");
    pdf.text(160, 289, "069-809-235");
    pdf.setTextColor(0, 0, 0);

    pdf.addImage(watermark, 'JPEG', 80, 260, 45, 28);
    
    pdf.save(`${fileName}_report.pdf`);
});
document.body.classList.remove("fixed-width");
document.querySelector('.ref_content_mobile').classList.add('hide');

};

export default downloadPdf;



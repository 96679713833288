import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import close_button from '../../../images/icons/close_button.png';
import checked_icon from '../../../images/icons/check-mark-10120.svg';

import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';

import DataTable from 'react-data-table-component';
import RangePopup from '../RangePopup';
import { formattingDate } from '../../helper/formattingDate';


const PhoneListPage = ({ showSuccessPopup }) => {
    const currentDate = new Date();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ calendar, setCalendar ] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);



    const [ selectionRange, setSelectionRange] = useState({
        startDate: new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: currentDate,
        key: 'selection',
    })
    const [filterText, setFilterText] = useState('');
    

    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-phone-list`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                startDate: selectionRange.startDate,
                endDate: selectionRange.endDate
            })
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            setData(data.data);
        })
        .catch();
    }, [selectionRange]);



    const requests = data?.map((item, id) => {
        return {
            phone_number: item.phone_number,
            moreRequests: item.moreRequests ? <img width={15} src={checked_icon} /> : <img width={15} src={close_button} style={{opacity: 0.6}}/>,
            moreKeywords: item.moreKeywords ? <img width={15} src={checked_icon} /> : <img width={15} src={close_button} style={{opacity: 0.6}}/>,
            freeConsultation: item.freeConsultation ? <img width={15} src={checked_icon} /> : <img width={15} src={close_button} style={{opacity: 0.6}}/>,
            created_at: formattingDate(item.created_at),
            updated_at: formattingDate(item.updated_at)
        }
    }).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))


    const filteredItems = requests?.filter(
        item => (
            item.phone_number && item.phone_number.toLowerCase().includes(filterText.toLowerCase()) ||
            item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()) ||
            item.updated_at && item.updated_at.toLowerCase().includes(filterText.toLowerCase()) 

        )
    );
    
    const subHeaderComponentMemo = useMemo(() => {
        return (
            <input 
            placeholder='Search in table'
            onChange={e => setFilterText(e.target.value)} 
            style={{border: '1px solid gray', borderRadius: 4}}/>
		);
	}, [filterText]);

    
    const columns = [
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: false,
        },
        {
            name: 'More Requests',
            selector: row => row.moreRequests,
            sortable: false,
        },
        {
            name: 'More Keywords',
            selector: row => row.moreKeywords,
            sortable: false,
        },
        {
            name: 'Free Consultation',
            selector: row => row.freeConsultation,
            sortable: false,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
        }
    ];


    const updateRange = (start, end) => {
        setSelectionRange({
            startDate: start,
            endDate: end,
            key: 'selection'
        })
    }

    const updateLastTime = ( interval ) => {
        setSelectionRange({
            startDate: new Date(currentDate.getTime() - interval * 24 * 60 * 60 * 1000),
            endDate: currentDate,
            key: 'selection',
        })
    }

    const handleSelect = (e) => {
        updateRange(e.selection.startDate, e.selection.endDate);
    }

    const showCalendar = () => {
        setCalendar(true);
    }

    const unshowCalendar = () => {
        setCalendar(false);
    }

	const handleRowSelected = (rows) => {
        setSelectedRows(rows.selectedRows);
    }
    
	const contextActions = useMemo(() => {
		const handleDelete = () => {

            if (!window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.phone_number)}?`)) {
                setToggleCleared(!toggleCleared);
                return;
            }

            const phoneNrs = selectedRows.map(item => item.phone_number);
            fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/delete/users`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNrs })
            })
            .then(response => {
                if (response.status === 200) {
                    setData(data.filter((item) => !phoneNrs.includes(item.phone_number)))
                    setSelectedRows([]);
                    setToggleCleared(!toggleCleared);
                    showSuccessPopup();
                }
            })
		};

		return (
			<Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }} icon>
				Delete
			</Button>
		);
	}, [data, selectedRows]);


    const paginationOptions = {
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const wrapperStyle = loading ? 'admin-wrapper blur' : 'admin-wrapper';

    return (
        <>
            {loading  ?
            <div className="spinner">
                <CircularProgress/>
                <p className='loading-info'>Loading...</p>
            </div>
            : null}

            {calendar ? 
            <RangePopup 
            selectionRange={selectionRange}
            unshowCalendar={unshowCalendar}
            handleSelect={handleSelect}/>
            : null}

            <div className={wrapperStyle}>
                <div className="c-container">
                    <DataTable
                        title="User List"
                        columns={columns}
                        data={filteredItems}
                        pagination
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                        selectableRows


                        clearSelectedRows={toggleCleared}
                        contextActions={contextActions}
                        onSelectedRowsChange={(rows) => setSelectedRows(rows.selectedRows)}
                        paginationComponentOptions={paginationOptions}
                        paginationRowsPerPageOptions={[10, 30, 50, 100]}
                    />
                </div>
            </div>

            <div className='keywords-range'>
                <Button variant="outlined" onClick={() => updateLastTime(30)}>Last 30 days</Button>    
                <Button variant="outlined" onClick={() => updateLastTime(10)}>Last 10 days</Button>    
                <Button variant="outlined" onClick={() => updateLastTime(7)}>Last 7 days</Button> 
                <Button variant="outlined" onClick={() => showCalendar()}>range</Button> 
            </div> 
        </>
    )
}

export default PhoneListPage;
import './previewCard.scss';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SkeletonImg from '../Skeleton/SkeletonImg';
import imgNotFound from '../../images/img_not_found.jpg';

const PreviewCard = ({screen, m_screen}) => {

    const { t } = useTranslation();
    const loading = useSelector(state => state.loading);

    return (
        <div className="report__data--wrapper report__preview">
            <div className="report__data--container">
                <p className='report__preview--title'>{t("website-preview")}</p>
                {
                !loading ? 
                <div className="report__review--img">
                    <img src={screen ? screen : imgNotFound} className='desktop_screen' alt="website screenshot" /> 
                    {m_screen ? 
                        <img src={m_screen} className="mobile_screen" alt="website screenshot" /> 
                    : null}
                </div> : 
                <SkeletonImg/>
                }
            </div>
        </div>
    );
}
 
export default PreviewCard;
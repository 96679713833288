import React, { useEffect, useRef } from 'react'
import './consultationPopup.scss';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ConsultationPopup = ({ showPricesPopup }) => {
    const blockRef = useRef(null);
    const { t } = useTranslation();
    useEffect(() => {
        const bounceInterval = setInterval(() => {
            blockRef.current.classList.toggle('bounce-container')
        }, 10000);

        return () => {
            clearInterval(bounceInterval);
        } 
    }, [])

    return (
        <div className="consultation-popup--wrapper" ref={blockRef}>
            <div className="consultation-popup--container">
                <p className='consultation-popup--text'>{t('free-consult-invit')}</p>
                <Button variant='primary' size='sm' style={{width: '100%'}}
                onClick={() => showPricesPopup("freeConsultation")}>
                    {t('get-free-consult')}
                </Button>
            </div>
        </div>
    )
}

export default ConsultationPopup
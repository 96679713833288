import React from 'react'


const Header = ({page, setPage}) => {
    return (
        <div className='admin-header'>
            <button 
            className={page === 'req' ? 'page-links active-page' : 'page-links'}    
            onClick={() => page !== 'req' && setPage('req')}>
            Requests</button>
            <button 
            onClick={() => page !== 'phonesList' && setPage('phoneList')}
            className={page === 'phoneList' ? 'page-links active-page' : 'page-links'}>
            User List</button>
            <button 
            onClick={() => page !== 'set' && setPage('set')}
            className={page === 'set' ? 'page-links active-page' : 'page-links'}>
            Settings</button>
        </div>
    )
}

export default Header;
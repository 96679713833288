import './AdminPanel.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 

import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';

import SettingPage from './SettingPage/SettingPage';
import Header from './Header/Header';
import DataTable from 'react-data-table-component';
import RangePopup from './RangePopup';
import PhoneListPage from './PhoneListPage/PhoneListPage';
import { formattingDate } from '../helper/formattingDate';
import SuccessPopup from './SuccessPopup';

const AdminPanel = () => {
    const currentDate = new Date();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ page, setPage ] = useState('req');
    const [ calendar, setCalendar ] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);


    const [ selectionRange, setSelectionRange] = useState({
        startDate: new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: currentDate,
        key: 'selection',
    })
    const [filterText, setFilterText] = useState('');
    

    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/getSessionHistory`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                startDate: selectionRange.startDate,
                endDate: selectionRange.endDate
            })
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            setData(data.data);
        });
    }, [selectionRange]);

    const requests = data?.map(item => {
        return {
            ip: item.ip,
            country: item.country,
            action: item.userAction,
            date: formattingDate(item.actionData),
            url: <a href={`${process.env.REACT_APP_PAGEDOMAIN}/base/${item.token}`}>{process.env.REACT_APP_PAGEDOMAIN}/base/{item.token}</a>,
        }
    }).sort((a, b) => new Date(b.date) - new Date(a.date))



    const filteredItems = requests?.filter(
        item => (
            item.ip && item.ip.toLowerCase().includes(filterText.toLowerCase()) ||
            item.country && item.country.toLowerCase().includes(filterText.toLowerCase()) ||
            item.date && item.date.toLowerCase().includes(filterText.toLowerCase()) ||
            item.action && item.action.toLowerCase().includes(filterText.toLowerCase())
        )
    );
    
    const subHeaderComponentMemo = useMemo(() => {
        return (
            <input 
            placeholder='Search in table'
            onChange={e => setFilterText(e.target.value)} 
            style={{border: '1px solid gray', borderRadius: 4}}/>
		);
	}, [filterText]);

    
    const columns = [
        {
            name: 'IP',
            selector: row => row.ip,
            sortable: false,
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            wrap: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'url',
            selector: row => row.url,
            sortable: false,
            wrap: true
        },
    ];


    const updateRange = (start, end) => {
        setSelectionRange({
            startDate: start,
            endDate: end,
            key: 'selection'
        })
    }

    const updateLastTime = ( interval ) => {
        setSelectionRange({
            startDate: new Date(currentDate.getTime() - interval * 24 * 60 * 60 * 1000),
            endDate: currentDate,
            key: 'selection',
        })

    }

    const handleSelect = (e) => {
        updateRange(e.selection.startDate, e.selection.endDate);
    }

    const showCalendar = () => {
        setCalendar(true);
    }

    const unshowCalendar = () => {
        setCalendar(false);
    }

	const handleRowSelected = (rows) => {
        setSelectedRows(rows.selectedRows);
    }
    
    const showSuccessPopup = () => {
        setIsVisiblePopup(true);
        setTimeout(() => {
            setIsVisiblePopup(false);
        }, 3000);
    }

	const contextActions = useMemo(() => {
		const handleDelete = async () => {

            if (!window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.id)}?`)) {
                setToggleCleared(!toggleCleared);
                return;
			}


            const ids = selectedRows.map(item => item.id);
            fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/delete/sessions`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ids})
            })
            .then(response => {
                if (response.status === 200) {
                    setData(data.filter((item) => !ids.includes(item.id)))
                    setSelectedRows([]);
                    setToggleCleared(!toggleCleared);
                    showSuccessPopup();
                }
            })  
		};

		return (
			<Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }} icon>
				Delete
			</Button>
		);
	}, [data, selectedRows]);

    const paginationOptions = {
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const wrapperStyle = loading ? 'admin-wrapper blur' : 'admin-wrapper';

    const reqPage = <>
            {loading  ?
            <div className="spinner">
                <CircularProgress/>
                <p className='loading-info'>Loading...</p>
            </div>
            : null}

            {calendar ? 
            <RangePopup 
            selectionRange={selectionRange}
            unshowCalendar={unshowCalendar}
            handleSelect={handleSelect}/>
            : null}

            <div className={wrapperStyle}>
                <div className="c-container">
                    <DataTable
                        title="Actions List"
                        columns={columns}
                        data={filteredItems}
                        pagination
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                        selectableRows
                        clearSelectedRows={toggleCleared}
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        paginationComponentOptions={paginationOptions}
                        paginationRowsPerPageOptions={[10, 30, 50, 100]}
                    />
                </div>

            </div>

            <div className='keywords-range'>
                <Button variant="outlined" onClick={() => updateLastTime(30)}>Last 30 days</Button>    
                <Button variant="outlined" onClick={() => updateLastTime(10)}>Last 10 days</Button>    
                <Button variant="outlined" onClick={() => updateLastTime(7)}>Last 7 days</Button> 
                <Button variant="outlined" onClick={() => showCalendar()}>range</Button> 
            </div> 
    </>


    return (
        <div className='App'>
            {isVisiblePopup ? <SuccessPopup message={"Saved!"}/> : null}
            <Header 
            page={page}
            setPage={setPage}
            loading={loading}/>
            {page === 'req' ? reqPage : null}
            {page === 'set' ? <SettingPage 
            loading={loading} 
            showSuccessPopup={showSuccessPopup}/> : null}
            {page === 'phoneList' ? <PhoneListPage 
            loading={loading} 
            showSuccessPopup={showSuccessPopup} /> : null}
        </div>
    )
}

export default AdminPanel
import '../KeywordsCard/keywordsCard.scss';
import './KeywordsDistribution.scss';

import info_icon from '../../images/icons/icons8-info (1).svg';
import not_data_icon from '../../images/icons/not-data-icon.png';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import GraphicBars from '../GraphicBars/GraphicBars';
import { useTranslation } from 'react-i18next';


const KeywordsDistributionCard = ({loading}) => {
  const { t } = useTranslation();
  const serpstatDistribInfo = useSelector(state => state.serpstatDistribInfo);


  return (
    <div className='keywords-card--wrapper'>
      <div className="keywords-card--header">

        <p className="keywords-title">{t('keywords-position-distribution')}</p>
        <div className='keywords-icon'>
            <img src={info_icon} alt="info" />
            <p className="keywords-icon__msg">{t('keywords-position-distribution-desc')}</p>
        </div>

      </div>
      <div className='keywords-card--container distrib-container'>
        {loading ? 
          <Skeleton variant='rounded'/>
        : 
            Array.isArray(serpstatDistribInfo) && serpstatDistribInfo.length ? 
            <GraphicBars data={serpstatDistribInfo}/> 
            : 
            <div className="not_data_icon">
                <img src={not_data_icon} alt='no data'/>
                <p>{t('not-data')}</p>
            </div>
            
        }
        </div>
    </div>
  )
}

export default KeywordsDistributionCard

import './serpstatOverview.scss';
import React, { useEffect, useState } from 'react'

import SerpstatMiniCard from '../../component/SerpstatMiniCard/SerpstatMiniCard';
import { useSelector } from 'react-redux';
import SerpstatHeader from '../../component/SerpstatHeader/SerpstatHeader';
import { getFavicon } from '../../component/helper/getFavicon';
import KeywordsCard from '../../component/KeywordsCard/KeywordsCard';
import KeywordsDistributionCard from '../../component/KeywordsDistribution/KeywordsDistributionCard';
import { useTranslation } from 'react-i18next';
import BacklinksCard from '../../component/BacklinksCard/BacklinksCard';
import SerpstatTrendGraphics from '../../component/SerpstatTrendGraphics/SerpstatTrendGraphics';


const SerpstatOverview = ({ loadingsState, loadSerpstatInfo, showPrices }) => {
    const { t } = useTranslation();
    const serpstatCardsInfo = useSelector(state => state.serpstatCardsInfo);

    const [icon, setIcon] = useState(null);
    const link = useSelector(state => state.link);
    const u = link ? new URL(link).hostname : null;

    const {
        serpstatCardsLoading, 
        serpstatDistribLoading,
        serpstatKeywordsLoading,
        serpstatBacklinksLoading,
        serpstatHistoryLoading
    } = loadingsState;

    const keywordsModulesItems = [
        [{
          id: t('all-keywords'),
          value: serpstatCardsInfo?.keywords
        },
        {
          id: t('new'),
          value: serpstatCardsInfo?.new_keywords
        },
        {
          id: t('lost'),
          value: serpstatCardsInfo?.out_keywords
        },
        {
          id: t('improved'),
          value: serpstatCardsInfo?.rised_keywords
        },
        {
          id: t('declined'),
          value: serpstatCardsInfo?.down_keywords
        }]
    ]

    
    useEffect(() => {
        setIcon(null)
        getFavicon(link).then(faviconUrl => setIcon(faviconUrl));
    }, [link]);


    const miniCardDataArr = [
        {
            id: 'visible',
            value: serpstatCardsInfo?.visible
        },
        {
            id: 'traff',
            value: serpstatCardsInfo?.traff,
        },
        {
            id: 'organic-keywords',
            value: serpstatCardsInfo?.keywords
        },
        {
            id: 'ad_keywords',
            value: serpstatCardsInfo?.ad_keywords
        }
    ]


  return (
    <>
        <div className="c-wrapper">
            <div className="c-container">

                <SerpstatHeader 
                pageData={{icon: icon, url: link, title: u}} 
                loading={false}
                loadSerpstatInfo={loadSerpstatInfo} 
                />
                <div className="serpstat-cards">
                    {miniCardDataArr?.map((data, id) => (
                        <SerpstatMiniCard 
                        data={data} 
                        loading={serpstatCardsLoading}
                        key={id}/>
                    ))}
                </div>

                <div className='serpstat-modules'>
                    {keywordsModulesItems?.map((items, key) => 
                    <KeywordsCard 
                    key={key} 
                    items={items} 
                    loading={serpstatKeywordsLoading}
                    showPrices={showPrices}/>)}

                    <KeywordsDistributionCard loading={serpstatDistribLoading}/>
                    <BacklinksCard loading={serpstatBacklinksLoading}/>
                </div>

                <SerpstatTrendGraphics loading={serpstatHistoryLoading}/>
            
            </div>
        </div>
    </>
  )
}

export default SerpstatOverview;
export const getFavicon = async (url) => {
    try {
      const domain = new URL(url).hostname;

      const faviconUrl = `https://${domain}/favicon.ico`;

      const img = new Image();
      img.src = faviconUrl;
      await new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Favicon not found'));
      });
      return faviconUrl;
    } catch (error) {
      console.error('Favicon error:', error);
      return null; 
    }
  }

// const getFavicon = async (url) => {
//   fetch(url)
//     .then(response => response.text())
//     .then(html => {
//       const parser = new DOMParser();
//       const doc = parser.parseFromString(html, 'text/html');
//       const linkTags = doc.querySelectorAll('link[rel*="icon"]');

//       if (linkTags.length > 0) {
//         const faviconURL = linkTags[0].getAttribute('href');
//         return faviconURL;
//       } else {
//         console.log('Favicon not found');
//       }
//     })
//     .catch(error => {
//       console.error('Favicon not found:', error);
//     });
// }

import React from 'react'
import info_icon from '../../../images/icons/icons8-info (1).svg';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const BacklinksItemLinks = ({ rowData, loading }) => {
    const { t } = useTranslation();

    const optimizeValueSize = (value) => {
        if (Math.abs(value) > 1000000000) return (value / 1000000000).toFixed(1) + 'B';
        else if (Math.abs(value) > 1000000) return (value / 1000000).toFixed(1) + 'M';
        else if (Math.abs(value) > 1000) return (value / 1000).toFixed(1) + 'K';
        return value;
    }

    const getSymbol = (value) => {
        if (value && value > 0) return '+';
        return null;
    }


    const mainRowData = rowData && rowData.length ? rowData[0] : null;
    rowData?.splice(0, 1);

    return (
        <div className="backlinks-item--container direction-column">

            <div className="backlinks-item--row">
                <div className="row__title">
                    <p className='row__title--text'>{t(mainRowData?.id)}</p>
                    <div className='backlinks-icon'>
                        <img src={info_icon} alt="info" />
                        <p className="backlinks-icon__msg">{t(`${mainRowData?.id}-desc`)}</p>
                    </div>
                </div>
                <div className="row__value">
                    {!loading ?
                    <>
                    <span className='row__value--fix'>
                        {mainRowData?.value !== null ? optimizeValueSize(mainRowData?.value) : <p className='no-data'>{t('not-data')}</p>}
                    </span>
                    <span className='row__value--change' style={{color: mainRowData?.change_value > 0 ? 'green' : 'red'}}>
                        {getSymbol(mainRowData?.change_value)}
                        {mainRowData?.change_value && mainRowData?.change_value !== null ? 
                            optimizeValueSize(mainRowData?.change_value) :
                        null}
                    </span>
                    </>:
                    <Skeleton variant='text' width={50}/>
                    }
                </div>
            </div>

            {rowData ? rowData.map((item, key) => (
            <div className="backlinks-item--subrow" key={key}>

                <p className="row__title">{t(item?.id)}</p>

                <div className="row__value">
                    {!loading ? 
                    <>
                    <span className='row__value--fix'>{
                        item?.value !== null ? optimizeValueSize(item?.value) : <p className='no-data'>{t('not-data')}</p>
                    }</span>

                    <span className='row__value--change' style={{color: item?.change_value > 0 ? 'green' : 'red'}}>
                        {getSymbol(item?.change_value)}
                        {item?.change_value && item?.change_value !== null ? 
                            optimizeValueSize(item?.change_value) :
                        null}
                    </span>
                    </>:
                    <Skeleton variant='text' width={50}/>
                    }
                </div>

            </div>
            )): null}

            

        </div>
    )
}

export default BacklinksItemLinks
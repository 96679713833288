import './topIssues.scss';

import { useSelector } from 'react-redux';
import IssuesCard from '../../component/IssuesCard/IssuesCard';
import ReportHeader from '../../component/ReportHeader/ReportHeader';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../component/LoadingSpinner/LoadingSpinner';


const TopIssues = ({pageData}) => {


    const loading = useSelector(state => state.loading);
    const {t} = useTranslation();

    const {LShiftData, cacheData, renderBlockingResourse, unusedCSS, redirectsInfo, criticalRequestChains, unusedJS} = pageData;
    const dataIssues = [LShiftData, cacheData, renderBlockingResourse, unusedCSS, redirectsInfo, criticalRequestChains, unusedJS];

    const isEmpty = (obj) => {
        return Object.entries(obj).length === 0;
    };

    const wrapperClasses = loading ? 'c-wrapper blur' : 'c-wrapper';

    return (
        <>
        {loading  ?
            <LoadingSpinner/>
        : null}
        <div className={wrapperClasses}>

            <div className="issues__container c-container">
                <ReportHeader pageData={pageData} />
                <div className="issues__cards--container">
                    {!isEmpty(pageData) ?  dataIssues.map((item, id) => {
                        return (
                            <IssuesCard key={id} data={item}/>
                        )
                    }) : 
                    <p className='not__info--issues'>{t('missing-info')}</p>}
                </div>

            </div>
        </div>
        </>
    ) 
    
}
 
export default TopIssues;
import React, { useEffect, useRef, useState } from 'react'
import './pricesPopup.scss';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import 'react-phone-input-2/lib/style.css';
import SuccessPopup from '../AdminPanel/SuccessPopup';
import ErrorPopup from '../ErrorPopup/ErrorPopup';
import close_button from '../../images/icons/close_button.png';


const PricesPopup = ({ unshowPrices, flag = "default" }) => {
    const [className, setClassName] = useState('price-popup--container');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [isNotValid, setIsNotValid] = useState(false);
    const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const { t } = useTranslation();
    

    const text = {
        "moreRequests": {
            warningText: t("request-limit-error"),
            mainText: t("get-more-request-text")
        },
        "moreKeywords": {
            warningText: t("get-more-keywords-text"),
            mainText: null
        },
        "freeConsultation": {
            warningText: t("get-free-consultation-text"),
            mainText: null
        },
        "default": {
            warningText: t("get-more-features-text"),
            mainText: null
        }
    }


    useEffect(() => {
        window.addEventListener('keydown', handleKey);
        setClassName('price-popup--container active');

        return () => document.removeEventListener('keydown', handleKey);
    }, []);

    const handleKey = (key) => {
        if (key.key === "Escape")
            unshowPrices();
        if (key.key === "Enter")
            submitHandler();
    }

    const submitHandler = async () => {
        isNotValid && setIsNotValid(false);
        if (validator.isMobilePhone(phone)) {
            await fetch(`${process.env.REACT_APP_DOMAIN}:8443/save-phone-number`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    phone: `+${phone}`,
                    flag
                })
            })
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    successHandler()
                }
                else if (response.status === 409) {
                    onError("Alreay Exists");
                }
                else {
                    onError();
                }
            })
        }
        else {
            notValidPhoneHandler();
        }
    }

    const onError = (errorMsg) => {
        setLoading(false);
        setErrorMsg(errorMsg);
        setTimeout(() => {
            setErrorMsg(undefined);
        }, 5000);
    }

    const successHandler = () => {
        setLoading(false);
        setIsVisibleSuccess(true);
        setTimeout(() => {
            setIsVisibleSuccess(false);
        }, 3000);
    }

    const notValidPhoneHandler = () => {
        setLoading(false);  
        setIsNotValid(true);
        onError(t('not-valid'));
    }


    return (
        <>
        {isVisibleSuccess ? <SuccessPopup message = {t('success-wait-response')}/> : null}
        {errorMsg ? <ErrorPopup errorMsg={errorMsg}/> : null}
        <div className={className}>

            <div className="cards-container">
                <div className="price-card">

                    <button 
                    onClick={unshowPrices}
                    className='close-button'>
                        <img src={close_button} alt="close tarifs"/>
                    </button>

                    <p className='prices-card--text' style={{
                        color: flag === "default" || flag === "freeConsultation" || flag === "moreKeywords" ? null : 'red', 
                        margin: flag === 'default' ? '5px 0' : '20px 0',
                        marginTop: 5
                    }}>
                        {text[flag].warningText}
                    </p>

                    <p className='prices-card--text'>
                        {text[flag].mainText}
                    </p>

                    
                    <div className='container-input--form'>
                        <div className="input-form">

                                <PhoneInput
                                country={'md'}
                                value={phone}
                                onChange={setPhone}
                                />

                                <Button
                                variant="primary" 
                                size="sm"
                                onClick={submitHandler}>
                                    {loading ? <Spinner /> : <>{t('enter')}</>}
                                </Button>

                        </div>
                        {isNotValid ? <p className="not-valid--phone">{t("not-valid")}</p> : null}
                    </div>
                </div>

                

                
            </div>

            

        </div>

        <div className="prices-popup--background">
        </div>
        </>
    )
}

export default PricesPopup
import React from "react";
import "./runningBar.scss"; // Создайте файл стилей RunningBar.css

const RunningBar = () => {
  return (
    <div className="running-bar-container">
      <div className="running-bar"></div>
    </div>
  );
};

export default RunningBar;

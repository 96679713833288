import { Skeleton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const BacklinksItemSDR = ({sdrValue, loading}) => {
  const { t } = useTranslation();

  return (
    <div className="backlinks-item--container sdr">
      <div className="sdr-data">
        <p className='sdr-title'>SDR</p>
        <div className="sdr-progress">
          {
            !loading ? 
            <>
              {sdrValue !== null ? <p>{sdrValue}</p> : <p className='no-data'>{t('not-data')}</p>}
            </> :
            <Skeleton variant='rounded' width={40}/>
          }
        </div>
      </div>
        <p className='sdr-description'>{t('sdr-desc')}</p>
    </div>
  )
}

export default BacklinksItemSDR
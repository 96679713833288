import './style/App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Route, Routes} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { getFavicon } from './component/helper/getFavicon';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useLocalStorage from './component/helper/useLocalStorage';
import { postActionData } from './services/PostActionData';

import Header from './component/Header/Header';
import SeoChecker from './pages/SeoCheker/SeoChecker';
import InputLinkPage from './pages/InputLinkPage/InputLinkPage';
import ReCAPTCHA from 'react-google-recaptcha';
import TopIssues from './pages/TopIssues/TopIssues';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import { Helmet } from 'react-helmet';
import SerpstatOverview from './pages/SerpstatOverview/SerpstatOverview';
import InputMailPopup from './component/inputMailPopup/InputMailPopup';
import PricesPopup from './component/PricesPopup/PricesPopup';
import { CookieService } from './services/CookieService';
import ConsultationPopup from './component/ConsultationPopup/ConsultationPopup';
import downloadPdf from './component/helper/downloadPdf';
import { isEmpty } from './component/helper/isEmpty';
import { getIP } from './component/helper/getIP';


function App() {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const recaptchaRef = useRef();
    const cookieService = new CookieService();

    const [capthcaIsDone, setCaptchaIsDone] = useState(false);
    const [capthcaMsg, setCaptchaMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [pageData, setPageData] = useState({});
    const { t, i18n } = useTranslation();
    const page = useSelector(state => state.currentPage);
    const [LS_language,] = useLocalStorage('lang');
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
    const email = useSelector(state => state.email);
    const showEmailInput = useSelector(state => state.showEmailInput);
    const [reqLimit, setReqLimit] = useState(undefined);
    const [flag, setFlag] = useState("moreRequests");
    const token = useSelector(state => state.token);
    const lang = useSelector(state => state.language);
    const downloadContentMobile = useSelector(state => state.downloadContentMobile);
    const isMobile = useSelector(state => state.isMobile);


    //loading states for serpstat request
    const [serpstatCardsLoading, setSerpstatCardsLoading] = useState(false);
    const [serpstatKeywordsLoading, setSerpstatKeywordsLoading] = useState(false);
    const [serpstatDistribLoading, setSerpstatDistribLoading] = useState(false);
    const [serpstatHistoryLoading, setSerpstatHistoryLoading] = useState(false);
    const [serpstatBacklinksLoading, setSerpstatBacklinksLoading] = useState(false);
    const [showPrices, setShowPrices] = useState(false);

    //serpstat info for download report
    const serpstatCardsInfo = useSelector(state => state.serpstatCardsInfo);
    const serpstatKeywordsInfo = useSelector(state => state.serpstatKeywordsInfo);
    const serpstatDistribInfo = useSelector(state => state.serpstatDistribInfo);
    const serpstatHistoryInfo = useSelector(state => state.serpstatHistoryInfo);
    const serpstatBacklinksInfo = useSelector(state => state.serpstatBacklinksInfo);
    const contentRefForPDF = useSelector(state => state.downloadContent);

    const serpstatDataObj = {
        serpstatCardsInfo,
        serpstatKeywordsInfo,
        serpstatDistribInfo,
        serpstatHistoryInfo,
        serpstatBacklinksInfo
    }

    useEffect(() => {
        const storedEmail = cookieService.getCookie('userEmail');
        if (storedEmail) {
            handleExistingEmail(storedEmail);
        }

        if (!reqLimit) {
            fetch(`${process.env.REACT_APP_DOMAIN}:8443/admin/get-request-limit`)
            .then(response => response.json())
            .then(data => setReqLimit(data.result));
        }

        recaptchaRef.current.execute();
        i18n.changeLanguage(LS_language || 'ro');
        dispatch({type: "language", payload: LS_language || 'ro'});
    }, [email])



    const loadSerpstatInfo = (url, se) => {
        requestDataSerpstatCards(url, se);
        requestDataSerpstatKeywords(url, se); 
        requestDataSerpstatDistrib(url, se);
        requestDataSerpstatBacklinks(url, se);
        requestDataSerpstatHistory(url, se);
    }

    const clearSerpstatInfo = () => {
        dispatch({type: 'setSerpstatCardsInfo', payload: {}});
        dispatch({type: 'setSerpstatKeywordsInfo', payload: {}});
        dispatch({type: 'setSerpstatDistribInfo', payload:  {}});
        dispatch({type: 'setSerpstatBacklinksInfo', payload: {}});
        dispatch({type: 'setSerpstatHistoryInfo', payload: {}});
    }

    //load data from the DB
    const loadData = async (token) => {
        dispatch({type: 'isLoading'})

        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/getSavedData?token=${token}`)
        .then(response => response.json())
        .then(response => {
            const data = response?.data?.dataRequest?.result;
            const url = response?.data?.dataRequest?.result?.url;
            const token = response?.data?.dataRequest?.token;
            dispatch({type: "link", payload: url});
            dispatch({type: "setSerpstatData", payload: {url}});
            loadSerpstatInfo(url, 'md');
        if (response.succes) {
            onLoadingData(data, url, token);
        }
        else {
            onError({result: {status: null}});
            navigate('/404/');
        }
        })
        .catch(() => {
            dispatch({type: "isNotLoading"});
            onError({result: {status: 404}});
        })
    }

    const handleExistingEmail = (storedEmail) => {
        dispatch({type: "setEmail", payload: storedEmail});
        dispatch({type: "showEmailInput", payload: false});
    }

    const emailVerification = () => {
        const userEmail = cookieService.getCookie("userEmail");
        if (userEmail) {
            return true;
        }
        else {
            dispatch({type: "showEmailInput", payload: true});
        }
        return false;
    }

    const onSubmit = async (newLink) => {
        if (!capthcaIsDone) return;
        dispatch({ type: "isLoading" });
        navigate('/base');
        clearSerpstatInfo();
        let apireq = false;
    
        let ip = await getIP();

        const userAgent = window.navigator.userAgent;
            
        if (!newLink.startsWith('http://') && !newLink.startsWith('https://')) {
            newLink = 'https://' + newLink;
        }

        dispatch({type: "isMobile"});
        dispatch({type: "link", payload: newLink});
            
        setErrorMsg('');
        setPageData({});
        
        let response = {url: newLink};
        // try {
        //     response = await fetch(`${process.env.REACT_APP_DOMAIN}:8443/getLastRedirect`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ url: newLink }),
        //     }).then((response) => response.json());
        // } catch {
        //     response = { url: newLink };
        // }
        
        const validatedURL = response.url;
        window.history.pushState({}, '', '/');

        await fetch(`${process.env.REACT_APP_DOMAIN}:8443/getdata/last`, {
            method: 'POST',
            credentials: 'include',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({userIP: ip, url: validatedURL})
        })
        .then((response) =>  response.json())
        .then((response) => {
            const token = response.data.dataRequest.token;

            onLoadingData(response.data.dataRequest.result, validatedURL, token);
            loadSerpstatInfo(validatedURL, 'md');
            apireq = true;

        })
        .catch(async () => {
            const checkLimit = await fetch(`${process.env.REACT_APP_DOMAIN}:8443/check-req-limit`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },  
                body: JSON.stringify({ip, userAgent})
            })
            .then(response => response.json())
        
            if (checkLimit.result.status === 429) {
                onError(checkLimit);
                apireq = true;
                return;
            }
        })

        if (!apireq) {
            loadSerpstatInfo(validatedURL, 'md');
            await fetch(`${process.env.REACT_APP_DOMAIN}:8443/getPageSpeedData?url=${validatedURL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },  
                body: JSON.stringify({ email, validatedURL })
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.result?.status >= 200 && data?.result?.status < 300) {
                    const token = data?.token;
                    postActionData(data, 'request', token, email, validatedURL);
                    data = data?.result;
                    onLoadingData(data, validatedURL, token);
                    return;
                }
                onError(data);
            })
            .catch((error) => {
                onError(error);
            })
        }
    };
    
    const onLoadingData = async (data, newLink, token) => {
        let favicon;
        newLink && data && await getFavicon(newLink).then(url => favicon = url);
        dispatch({type: "setRequestToken", payload: token});
        
        setPageData({...data, icon: favicon});
        dispatch({type: "isNotLoading"})  
        token && window.history.pushState({}, '', `/base/${token}`); 
    }
    


    const onError = (data) => {
        dispatch({type: "setRequestToken", payload: null});
        setPageData({});
        navigate('/base');
        dispatch({type: "isNotLoading"});

        if (data?.result?.status != null) {
            if (data?.result?.status >= 500 && data?.result?.status < 600) 
                setErrorMsg(t("server-error"));
            else if (data?.result?.status === 408) {
                setErrorMsg(t("timeout-exceeded"));
            }
            else if (data?.result?.status === 429) {
                setErrorMsg(t("request-limit-error", {reqLimit}));
                showPricesPopup("moreRequests");
            } else setErrorMsg(t("link-error"));
        }   

    }

    const onChangeCaptcha = async (value) => {
        if (value) {
            try {
                const response = await fetch(`${process.env.REACT_APP_DOMAIN}:8443/verified-captcha`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({recaptchaToken: value})
                }).then(response => response.json())

                if (response.success) {
                    console.log("captcha is verified")
                    setCaptchaIsDone(true);
                    setCaptchaMsg(false);
                }
                else {
                    setCaptchaIsDone(false);
                    setCaptchaMsg(true);
                }
            }
            catch (error) {
                setCaptchaIsDone(false);
                setCaptchaMsg(true);
                console.log(error.message);
            }
        }
        else {
            setCaptchaIsDone(false);
            setCaptchaMsg(true);
        }
    }

    //serpstat request function
    const requestDataSerpstatCards = async (link, se) => {
        dispatch({type: "setSerpstatData", payload: {url: link}});
        setSerpstatCardsLoading(true);
        dispatch({type: 'setSerpstatCardsInfo', payload: {}});
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/get-serpstat-summary`, 
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },  
            body: JSON.stringify({url: link, se, email})
        })
        .then(response => response.json())
        .then(data => { 
            if(data.success) {
                setSerpstatCardsLoading(false);
                dispatch({type: 'setSerpstatCardsInfo', payload: data.data});
            } else setSerpstatCardsLoading(false);
        })
        .catch(error => {
            setSerpstatCardsLoading(false);
        })
    }

    const requestDataSerpstatKeywords = async (link, se) => {

        setSerpstatKeywordsLoading(true);
        dispatch({type: 'setSerpstatKeywordsInfo', payload: {}});
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/get-serpstat-keywords`, 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url: link, count: 12, se})
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setSerpstatKeywordsLoading(false);
                dispatch({type: 'setSerpstatKeywordsInfo', payload: data.data.data});
            } else setSerpstatKeywordsLoading(false);
        })
        .catch(error => {
                setSerpstatKeywordsLoading(false);
        })
    }
    
    const requestDataSerpstatDistrib = async (link, se) => {

        setSerpstatDistribLoading(true);
        dispatch({type: 'setSerpstatDistribInfo', payload:  {}});
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/get-serpstat-keywords-distributions`, 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url: link, se})
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setSerpstatDistribLoading(false)
                dispatch({type: 'setSerpstatDistribInfo', payload:  data.data});
            } else {
                setSerpstatDistribLoading(false)
            }
        })
        .catch(() => {
            setSerpstatDistribLoading(false)
        })
    }

    const requestDataSerpstatBacklinks = async (link, se) => {

        setSerpstatBacklinksLoading(true);
        dispatch({type: 'setSerpstatBacklinksInfo', payload: {}});
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/get-serpstat-backlinks-summary`, 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({url: link, se})
        })
        .then(response => response.json())
        .then(data => {
        if (data.success) {
            dispatch({type: 'setSerpstatBacklinksInfo', payload: data.data});
            setSerpstatBacklinksLoading(false);
        } 
        else setSerpstatBacklinksLoading(false);
        })
        .catch(() => {
            setSerpstatBacklinksLoading(false);
        })
    }
    const requestDataSerpstatHistory = async (link, se) => {

        setSerpstatHistoryLoading(true);
        dispatch({type: 'setSerpstatHistoryInfo', payload: {}});
        fetch(`${process.env.REACT_APP_DOMAIN}:8443/get-history-from-domain`, 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },  
            body: JSON.stringify({url: link, se})
        })
        .then(response => response.json())
        .then(data => { 
            if(data.success) {
                dispatch({type: 'setSerpstatHistoryInfo', payload: data?.data?.data});
                setSerpstatHistoryLoading(false);
            } else setSerpstatHistoryLoading(false);
        })
        .catch(error => {
            console.log(error)
            setSerpstatHistoryLoading(false);
        })
    }

    const unshowPricesPopup = () => {
        setShowPrices(false);
    }

    const showPricesPopup = (flag) => {
        setFlag(flag);
        setShowPrices(true);
    }


    //download report
    const handleDownloadClick = () => {
        if (emailVerification() && !isEmpty(pageData)) {
            downloadPdf(contentRefForPDF, downloadContentMobile, pageData?.title, isMobile, pageData, lang, serpstatDataObj, token);
        }
    }

    //Meta tags
    const existingMetaTag = document.querySelector('meta[name="description"]');
        if (existingMetaTag) {
        existingMetaTag.setAttribute('content', t("meta-desc"));
        } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = 'description';
        newMetaTag.content = t("meta-desc");
        document.head.appendChild(newMetaTag);
        }



    //pages
    const content = {
        "base": 
        <SeoChecker 
        emailVerification={emailVerification}
        pageData={pageData} 
        loadData={loadData}
        showPrices={showPricesPopup}
        handleDownloadClick={handleDownloadClick}/>,
        "issues": 
        <TopIssues pageData = {pageData}/>,
        "serpstat": 
        <SerpstatOverview 
        loadSerpstatInfo={loadSerpstatInfo}
        pageData={pageData} 
        showPrices={showPricesPopup}
        loadingsState={{
            serpstatCardsLoading,
            serpstatKeywordsLoading,
            serpstatHistoryLoading,
            serpstatDistribLoading,
            serpstatBacklinksLoading
        }}/>
    }
    
    return (
        <div className="App">
            <>
            <Helmet>
                <title>{process.env.REACT_APP_TITLE}</title>
            </Helmet>

            {!showEmailInput ? null : 
            <InputMailPopup handleDownloadClick={handleDownloadClick} />}

            {!showPrices ? null : 
            <PricesPopup 
            unshowPrices={unshowPricesPopup} 
            flag={flag}/>}

            <ConsultationPopup showPricesPopup={showPricesPopup}/>
            
            <Header
            onSubmit={onSubmit}
            errorMsg={errorMsg}/>
        
                <Routes>
                    <Route path='/' element = {
                    <InputLinkPage 
                    capthcaMsg={capthcaMsg}
                    onSubmit={onSubmit} 
                    errorMsg={capthcaMsg ? t("captcha-error") : errorMsg }/>} />
                    <Route path='/base/:id' element={content[page]}/>
                    <Route path='/base' element={content[page]} />
                    <Route path='*' element={<PageNotFound />} status={404} />
                </Routes>
            

            <div className="captcha__container">
            <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={captchaKey}
            onChange={onChangeCaptcha}
            size="invisible"/>
            </div>
            </>
        </div>
    );
}

export default App;
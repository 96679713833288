import './reportHeader.scss';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SkeletonIcon from '../Skeleton/SkeletonIcon';
import SkeletonText from '../Skeleton/SkeletonText';

import img_not_found from '../../images/icons/img_not_found.png';
import cloud_download_icon from '../../images/icons/cloud-download-2.svg';


const ReportHeader = ({
    pageData, 
    showPrices,
    handleDownloadClick
}) => {
    
    const { t } = useTranslation();
    const loading = useSelector(state => state.loading);
    const page = useSelector(state => state.currentPage);

    
    const isEmpty = (obj) => {
        return Object.entries(obj).length === 0;
    };
    
    
    let {icon, url, title} = pageData;
    icon = icon ? icon : img_not_found;
    
    const buttonClasses = loading || isEmpty(pageData) ? "download-button disabled-button" : "download-button";
    
    const pageHeader = {
        "base":

        <>
        <button 
        className={buttonClasses} 
        style={{backgroundColor: "#0d6efd"}}
        onClick={loading ? null : showPrices}>
            {loading || isEmpty(pageData) ?
            <span class="text-traf md-body-2 font-bold hide-xs">{t("tarrifs")}</span> :
            <span class="text-traf md-body-2 font-bold hide-xs">{t("tarrifs")}</span>
            }
        </button>

        <button 
        className={buttonClasses} 
        onClick={handleDownloadClick}>
            <img src={cloud_download_icon} alt="cloud download"/>
            {loading || isEmpty(pageData) ?
            <span class="text-dwnld md-body-2 font-bold hide-xs">{t("download-button-text")}</span> :
            <span class="text-dwnld md-body-2 font-bold hide-xs">{t("download-button-text")}</span>
            }
        </button>

        </>,
        "issues": <p className='issues__title'>{t('top-issues')}</p>,
    }

    return (
            <div className='report__row d-flex flex-row'>
                <div className="report__main-info d-flex">
                    {loading ? <SkeletonIcon /> :
                    <div className="report__web-icon">
                        <img src={icon} alt="icon" />
                    </div>
                    }
                    <div className="report__web-text">
                        {loading ? <SkeletonText/> : 
                        <>
                            <div className="report__web--title">{title}</div>
                            <a className="report__web--link" href={url} target='_blanc'>{url}</a>
                        </>}
                    </div>
                </div>

                {pageHeader[page]}
            </div>
    );
}
 
export default ReportHeader;